<template>
    <img :style="{display:'block',
  width:'100%',
  height:this.height+'px',
  }" src="@/assets/amartha2.png">
    <!-- <v-img :width="width+'px'" :height="height+'px'" src="@/assets/amartha_fix.png"></v-img> -->
</template>
<script>
export default {
    data () {
      return {
        height:null,
        width:null,
      }
    },
    beforeMount(){
       this.height = window.innerHeight
        this.width = window.innerWidth
          this.Hide()
        
    },
    methods:{
        async Hide(){
          console.log('ok')
            await setTimeout(()=>{this.$HideBanner()}, 6000);
        }
    }
}
</script>
<style scoped>
html,body{
    margin:0;
    height:100%;
}
</style>